@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: var(--bg-main);
  color: var(--text-main);
  overflow-x: hidden;
}

#bees-logo-border {
  color: #fff;
}

.show {
  display: block !important;
}

.hidden {
  display: none !important;
}

.winner {
  &:hover {
    color: #f6a917;
  }
}

@media screen and (max-width: 586px) {
  body {
  }
}

.wrong-network {
  min-width: 500px;
  min-height: 350px;
  background-color: var(--text-primary);
  border-radius: var(--b-radius-card);
  padding: 2rem;
  max-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  header {
    width: 100%;
    text-align: center;
    color: #fff;
  }

  h1 {
    color: #fff;
  }

  p {
    color: var(--text-secondary);
  }
}

.connect-address {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  color: var(--text-primary);
  font-size: 25px;
  font-weight: 600;
}

.section {
  // width: 100vw;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

#App {
  height: 100vh;
}

#page-wrap {
  height: auto;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: var(--bg-main);
}

::-webkit-scrollbar-thumb {
  background: rgb(92, 92, 92);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Footer {
  display: flex;
  justify-content: center;

  padding: 4rem;
  ul {
    display: flex;
    list-style: none;
    gap: 15px;
    svg {
      width: 23px;
      height: 23px;
      cursor: pointer;
    }

    #discord {
      fill: #5165f6;
    }

    #youtube {
      fill: #f71801;
    }

    #twitter {
      fill: #3da1f2;
    }

    #medium {
      fill: #fff;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.bm-item {
  list-style: none;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: var(--text-primary);
  transition: color 0.2s;
  padding: 10px 5px;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
  margin-bottom: 0;

  a {
    color: inherit;
    text-decoration: none;
  }

  border-radius: 10px;
  &:hover {
    background-color: #373a47;
  }
}

.socials {
  cursor: auto;
  color: #fff;

  &:hover {
    background-color: transparent;
  }
  ul {
    li {
      padding: 10px 5px;
      cursor: pointer;
      border-radius: 10px;
      color: #fff;

      a {
        color: #fff;
        text-decoration: none;
      }

      &:hover {
        background-color: #373a47;
      }
      svg {
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      #discord {
        fill: #5165f6;
      }

      #youtube {
        fill: #f71801;
      }

      #twitter {
        fill: #3da1f2;
      }

      display: flex;
      align-items: center;
      gap: 10px;

      &:last-child {
        margin-bottom: 10px;
      }
    }
  }

  .wallet-container {
    width: 100%;
  }
}

.bm-item:hover {
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

.bm-burger-bars {
  background: #fff;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #fff;
}

.bm-menu {
  background: #262626;
  padding: 2.5em 10px;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.close-icon {
}

//loader

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  #bees-logo-border {
    fill: transparent;
  }

  .caption {
    position: absolute;
    bottom: -35px;
  }
}

.div {
  position: absolute;
  width: 69px;
  height: 39px;
}

.div:nth-of-type(2) {
  transform: rotate(60deg);
}
.div:nth-of-type(3) {
  transform: rotate(-60deg);
}

.div .div {
  width: 100%;
  height: 100%;
  position: relative;
}

.div .div span {
  position: absolute;
  width: 4px;
  height: 0%;
  background: #fff;
  z-index: 999999;
}

.h1 {
  left: 0;
  animation: load1 7.2s ease infinite;
}
.h2 {
  right: 0;
  animation: load2 7.2s ease 0.6s infinite;
}
.h3 {
  right: 0;
  animation: load3 7.2s ease 1.2s infinite;
}
.h4 {
  right: 0;
  animation: load4 7.2s ease 1.8s infinite;
}
.h5 {
  left: 0;
  animation: load5 7.2s ease 2.4s infinite;
}
.h6 {
  left: 0;
  animation: load6 7.2s ease 3s infinite;
}

@keyframes load1 {
  0% {
    bottom: 0;
    height: 0;
  }
  6.944444444% {
    bottom: 0;
    height: 100%;
  }
  50% {
    top: 0;
    height: 100%;
  }
  59.944444433% {
    top: 0;
    height: 0;
  }
  /*   91.6666667%{top:0;height:0%;} */
}

@keyframes load2 {
  0% {
    top: 0;
    height: 0;
  }
  6.944444444% {
    top: 0;
    height: 100%;
  }
  50% {
    bottom: 0;
    height: 100%;
  }
  59.944444433% {
    bottom: 0;
    height: 0;
  }
  /*   91.6666667%{bottom:0;height:0%} */
}

@keyframes load3 {
  0% {
    top: 0;
    height: 0;
  }
  6.944444444% {
    top: 0;
    height: 100%;
  }
  50% {
    bottom: 0;
    height: 100%;
  }
  59.94444443% {
    bottom: 0;
    height: 0;
  }
  /*   91.6666667%{bottom:0;height:0%;} */
}

@keyframes load4 {
  0% {
    top: 0;
    height: 0;
  }
  6.944444444% {
    top: 0;
    height: 100%;
  }
  50% {
    bottom: 0;
    height: 100%;
  }
  59.94444443% {
    bottom: 0;
    height: 0;
  }
  /*   91.6666667%{bottom:0;height:0%;} */
}

@keyframes load5 {
  0% {
    bottom: 0;
    height: 0;
  }
  6.944444444% {
    bottom: 0;
    height: 100%;
  }
  50% {
    top: 0;
    height: 100%;
  }
  59.94444443% {
    top: 0;
    height: 0;
  }
  /*   91.6666667%{top:0;height:0%;} */
}

@keyframes load6 {
  0% {
    bottom: 0;
    height: 0;
  }
  6.944444444% {
    bottom: 0;
    height: 100%;
  }
  50% {
    top: 0;
    height: 100%;
  }
  59.94444443% {
    top: 0;
    height: 0;
  }
  /*   91.6666667%{top:0;height:0%;} */
}
