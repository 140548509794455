.stepIcon {
  border: 2px solid #000;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  font-weight: 700;
  align-items: center;

  &.active {
    background-color: var(--text-primary);
    color: #fff;
    border: 0.5px solid rgba(0, 0, 0, 0.236);
  }

  &.completed {
    width: 33px;
    height: 33px;
    border: transparent;
    background-color: #a5f2d7;
    color: #3eb78d;
  }
}
