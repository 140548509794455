.sign-section {
  width: 100%;
  display: flex;

  flex-direction: column;
  align-items: center;
  max-width: 670px;
  .sign {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
