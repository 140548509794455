.main-container {
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  &__actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    a {
      text-decoration: none;
    }
    button {
      width: 300px;
      min-width: 185px;
    }
  }
}
