.qr {
  button {
    width: 100%;
  }
}

.download-container {
  header {
    color: #3e3e3e;
    font-size: 20px;
    margin: 0.5rem;
  }
}

.download-qr {
  cursor: pointer;
  border: 1px solid var(--text-muted);
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;

  section {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 18px;
  }
}
