:root {
  //backgrounds
  --bg-main: #101010;

  //texts colors
  --text-main: #fff;
  --text-primary: #f6a917;
  --text-secondary: #f8669e;
  --text-success: #61c149;
  --text-muted: #c4c4c4;

  //utils
  --b-radius-card: 15px;
}
