.honey-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  text-align: center;
  background-color: #262626;

  header {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 25px;
    font-weight: 900;
    color: var(--text-primary);
    margin-bottom: 20px;
    img {
      width: 45px;
      pointer-events: none;
    }
  }

  &__info {
    max-width: 500px;
    text-align: center;
  }

  .view-more {
    margin-top: 10px;
    margin-bottom: 2rem;
    color: var(--text-primary);
    cursor: pointer;
  }

  .buy-honey {
    display: flex;
    width: 90%;
    justify-content: space-between;
    padding: 5px 10px;
    padding-right: 20px;
    background-color: #fff;
    align-items: center;
    border-radius: 20px;
    margin-top: 10px;

    margin-bottom: 20px;
    .actions {
      display: flex;
      gap: 5px;
      width: calc(100% / 3);
      border-radius: 10px;

      button {
        padding: 2px;
        background-color: #dad7d7;
        color: rgb(77, 77, 77);
      }

      &:focus-visible {
        outline: none;
      }
      input {
        max-width: 70px;
        border: 1px solid transparent;
        border-radius: 5px;
        &:focus-visible {
          outline: rgb(226, 226, 226) solid 0.5px;
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }

      select {
        border: none;
        background-color: transparent;
        &:focus-visible {
          outline: none;
        }
      }
    }

    .result {
      display: flex;
      gap: 10px;
      align-items: center;
      // flex-direction: column;
      min-width: calc(100% / 3);
      color: #000;
      font-size: 13px;
      small {
        font-size: 15px;
      }
    }
  }

  .honey-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .coming-soon {
      z-index: 9999;
      position: absolute;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.5;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      header {
        color: #fff;
        opacity: 1 !important;
      }
    }
  }

  &__action {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 500px) {
  .honey-section {
    .buy-honey {
      flex-direction: column;
      justify-content: center;
      align-content: center;
      gap: 10px;
      width: 70%;
      button {
        padding: 2px;
        background-color: #dad7d7;
        color: rgb(77, 77, 77);
      }
      .actions {
        display: unset;
        width: 100%;
      }
    }
  }
}
