.navbar {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  #bees-logo-border {
    fill: #fff;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 20px;
    list-style: none;
    color: var(--text-primary);
    font-weight: 700;

    li {
      cursor: pointer;
      user-select: none;
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  &__right {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      width: 23px;
      height: 23px;
      cursor: pointer;
    }

    #discord {
      fill: #5165f6;
    }

    #youtube {
      fill: #f71801;
    }

    #twitter {
      fill: #3da1f2;
    }

    #medium {
      fill: #fff;
    }
  }

  .navbar-right__container {
    display: flex;
    gap: 20px;
    align-items: center;
  }
}

.wallet-container {
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 20px;
  border-radius: 20px;
  .logout {
    display: flex;
    align-items: center;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.wallet-btn {
  &__disconnected {
    border-radius: 20px;
    padding: 8px 30px;
  }

  &__connected {
    padding: 0;
    max-height: 40.5px;
    border-radius: 20px !important;
    padding-right: 20px;
    padding-left: 0 !important;
    border: 1px solid var(--bg-main) !important;
    .left {
      width: 100%;
      max-height: 40.5px;
      display: flex;
      padding: 0.5rem 1.5rem;
      background: var(--bg-main);
      border-radius: 20px;
      margin-right: 10px;
      align-items: center;
      gap: 10px;

      img {
        width: 20px;
        height: 20px;
        object-fit: cover;
      }
    }

    &__container {
      width: 100%;
      display: inherit;
      align-items: inherit;
      justify-content: inherit;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;

  a {
    text-decoration: none;
    color: #000;

    &:hover {
      color: var(--text-primary);
    }
  }

  svg {
    width: 50px;
    height: 50px;
    #bees-logo-border {
      fill: #fff;
    }
  }

  .bg-menu-ico {
    display: none;
  }

  .header-info-container {
    display: flex;
    gap: 20px;

    .seedz {
      display: flex;
      gap: 5px;
      align-items: center;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media screen and (max-width: 586px) {
  .header {
    flex-wrap: wrap;
    padding: 1rem 0.5rem;
    justify-content: space-between;
    .wallet-container {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-self: flex-end;
    }
  }
}

@media screen and (max-width: 1224px) {
  .navbar {
    &__left {
      li {
        display: none;
      }

      .logo {
        display: block;
      }
    }
    .navbar-right__container {
      display: none;
    }
  }
}
