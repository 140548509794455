.alert {
  &__container {
    position: fixed;
    top: 80px;
    right: 10px;
    min-width: 400px;
    max-width: 450px;
    background-color: #000 !important;
    color: #fff !important;
    display: flex;
    align-items: center;
    border-radius: 15px !important;
    box-shadow: rgba(1, 4, 7, 0.619) 0px 0px 10px 0px !important;
    cursor: pointer;
    z-index: 99;
  }
}

.MuiAlert-icon {
  width: 60px;
  height: 60px;

  svg {
    width: 100% !important;
    height: 100% !important;
  }
}

.css-acap47-MuiAlert-message {
  width: 100%;
  word-break: break-all;

  a {
    color: #f6a917;
    text-decoration: none;
    margin: 0.3rem 0;
    display: block;
  }
}
