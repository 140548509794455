//yfu
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

//goats
@import url("https://fonts.googleapis.com/css2?family=Slackey&display=swap");
//desc
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300&display=swap");

.banner-wrapper {
  padding-bottom: 33.25%;
  position: relative;

  .banner-background {
    background: url("../../../assets/slider/banner.png");
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-size: cover;
    background-position: center;
  }
}

.slider-section {
  .item {
    width: 100%;
    height: 300px;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-size: cover !important;
    background-repeat: no-repeat !important;

    .item-info {
      max-width: 400px;
      text-align: center;
      font-family: "Red Hat Display";
      font-size: 18px;
    }
  }

  .goats {
    background: url("../../../assets/slider/bannerGoats2.png");

    header {
      font-family: "Slackey", "Poppins";
      font-size: 30px;
      max-width: 300px;
      text-align: center;
    }
  }

  .yfu {
    background: url("../../../assets/slider/bannerYFU2.png");
    header {
      font-family: "Roboto", "Poppins";
      font-size: 30px;
      color: #da552b;
      font-weight: 900;
    }
  }

  .pixel {
    background: url("../../../assets/slider/bannerPixel2.png");

    header {
      width: 300px;
      height: 30px;
      position: relative;

      margin-bottom: 20px;
      img {
        width: 200px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: -100px;
        left: 0;
        right: 0;
        text-align: center;
      }
    }
  }

  //override styles
  .slick-next {
    right: 25px;

    //arrow size
    &::before {
      font-size: 30px;
    }
  }

  .slick-prev {
    z-index: 10;
    left: 13px;

    &::before {
      font-size: 30px;
    }
  }

  .slick-dots {
    bottom: 0;
    li {
      button {
        &::before {
          font-size: 15px;
          color: rgb(224, 224, 224);
        }
      }
    }

    .slick-active {
      button {
        &::before {
          color: rgb(254, 254, 254);
        }
      }
    }
  }

  .item-action {
    a {
      text-decoration: none;
    }
  }
}
