.sbu-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  justify-content: center;
  padding: 3rem 1rem;
  background-color: #262626;
  width: 100vw;

  &__header {
    h3 {
      font-size: 25px;
      font-weight: 600;
      &:first-child {
        font-size: 70px;
        color: var(--text-primary);
        text-shadow: 2px 2px #f9b4138e;
      }
    }
  }

  b {
    color: var(--text-primary);
  }

  p {
    width: 40%;
  }

  &__link {
    margin-bottom: 4rem;
    p {
      margin-bottom: 10px;
      width: unset;
    }

    a {
      text-decoration: none;
    }

    button {
      margin: 0 auto;
      border-radius: 20px;
      display: flex;
      gap: 10px;
      background-color: rgba(20, 128, 221, .8);

      svg {
        width: 30px;
        height: 30px;
      }
    }
    button:hover {
      background-color: rgba(20, 128, 221, 1);
    }
  }

  &__footer {
    font-size: 30px;
    font-weight: 600;
    color: var(--text-primary);
  }
}

@media screen and (max-width: 400px) {
  .sbu-section {
    p {
      width: 100%;
    }
  }
}
