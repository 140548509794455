.auction-section {
  padding: 1rem 20px;
  width: 100%;
  position: relative;

  user-select: none;

  .coming-soon {
    width: 100vw;
    height: 100%;
    position: absolute;
    background: #000;
    z-index: 1;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;

    header {
      color: #fff;
      font-size: 35px;
      opacity: 1 !important;
    }
  }

  &__header {
    padding: 1rem 2rem;
    &__treasuries {
      display: flex;
      gap: 20px;
      margin-top: -2rem;
      .treasury {
        align-items: center;
        padding: 0.8rem;
        border-radius: 20px;
        border: 1px solid #fff;
      }
      .burn {
        display: flex;
        flex-direction: row;
        gap: 15px;
        padding: 0.4rem;
        border-radius: 20px;
        background: linear-gradient(
          90deg,
          rgba(204, 139, 19, 1) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        .item {
          img {
            margin: 0 2px;
            width: 15px;
          }
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          small {
            font-size: smaller;
          }
          span {
            font-size: small;
          }
        }
      }
    }

    &__down {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-header {
        .handleAuctions {
          display: flex;
          gap: 20px;
        }

        button {
          margin-top: 10px;
        }
      }

      .prev-auction {
        cursor: pointer;
        display: flex;
        align-items: center;
        user-select: none;
        gap: 10px;

        .back {
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 24px;
          height: 24px;
        }
        svg {
          color: #000;
        }
      }

      .time-remaining {
        display: flex;
        flex-direction: column;
        min-width: 350px;

        &__header {
          padding-left: 60px;
        }

        &__time {
          display: flex;
          align-items: center;
          gap: 20px;

          svg {
            width: 43px;
            height: 43px;
          }

          p {
            font-size: 40px;
            font-weight: 900;
          }
        }

        &__date {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  &__content {
    &__ended {
      width: 100%;

      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      header {
        color: var(--text-primary);
        font-size: 42px;
        font-weight: 900;
      }

      .time-remaining {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        svg {
          width: 33px;
          height: 33px;
        }

        p {
          font-size: 30px;
          font-weight: 900;
        }
      }

      .execute {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin-top: 30px;
        gap: 9px;

        header {
          font-size: 20px;
        }

        button {
          margin: 0 auto;
        }
      }

      &__content {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(16.6666666667vw, 2fr));
        grid-gap: 20px;
        gap: 20px;
        font-size: 15px;
        margin-top: 20px;

        .bee {
          display: flex;
          grid-gap: 10px;
          gap: 10px;
          flex-direction: column;
          align-items: center;

          &__img {
            border-radius: 10px;
            position: relative;
            margin-bottom: 40px;

            img {
              width: 100%;
              height: 100%;
              max-height: 200px;
              max-width: 200px;
              border-radius: 10px;
              object-fit: contain;
              z-index: 3;
            }

            &__empowermint {
              position: absolute;
              width: 80px !important;
              height: 80px !important;
              z-index: 4 !important;
              left: -10px;
              bottom: -40px;
            }
            small {
              color: #915001;
              // background-color: #3c3422;
              font-weight: bolder;
              position: absolute;
              z-index: 4 !important;
              left: 75px;
              bottom: -40px;
            }
          }

          &__info {
            text-align: center;

            .trait {
              color: var(--text-primary);
              display: block;
            }
          }
        }
      }
    }

    &__bees {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(calc(100vw / 6), 1fr));
      gap: 20px;
      font-size: 15px;
      margin-top: 20px;
      .bee {
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: center;

        &__info {
          .trait {
            color: var(--text-primary);
            display: block;
          }

          text-align: center;
        }

        &__infoMain {
          .trait {
            color: var(--text-primary);
            display: block;
          }

          text-align: center;
          margin-top: -30px;
        }

        &__img {
          border-radius: 10px;
          position: relative;
          margin-bottom: 40px;

          img {
            width: 100%;
            height: 100%;
            max-height: 200px;
            max-width: 200px;
            border-radius: 10px;
            object-fit: contain;
            z-index: 3;
          }

          &__empowermint {
            position: absolute;
            width: 80px !important;
            height: 80px !important;
            z-index: 4 !important;
            left: -10px;
            bottom: -40px;
          }
          small {
            color: #915001;
            // background-color: #3c3422;
            font-weight: bolder;
            position: absolute;
            z-index: 4 !important;
            left: 75px;
            bottom: -40px;
          }
        }

        &__auction {
          button {
            border-radius: 20px;
          }
        }
      }
    }
  }

  &__footer {
    margin-top: 14px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    // margin: 3.5rem 0;
    margin-bottom: 2rem;

    p {
      text-align: center;
    }

    .view-more {
      cursor: pointer;
      user-select: none;
      span {
        color: var(--text-primary);
      }
    }
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 435px;
}

@media screen and (max-width: 529px) {
  .auction-section {
    &__header {
      &__treasuries {
      }

      &__down {
        padding: 10px 0;
        flex-direction: column;
        gap: 10px;

        .time-remaining__date {
          padding-left: 60px;
          justify-content: flex-start;
        }
      }
    }

    &__content {
    }
  }

  .auction-section__content__bees {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 15px;
  }
}

@media screen and (max-width: 681px) {
  .auction-section__content__ended__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-gap: 15px;
    gap: 15px;
  }
}

@media screen and (max-width: 482px) {
  .auction-section {
    .auction-section__header__treasuries {
      flex-direction: column;
    }
  }

  .auction-section__content__bees {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  .auction-section__content__ended__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
