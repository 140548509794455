.mint-card {
  min-width: 400px;
  min-height: 300px;
  max-height: 400px;
  max-width: 300px;
  background-color: var(--text-primary);
  border-radius: var(--b-radius-card);
  color: #fff;
  padding: 2rem;

  &__waiting {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__spinner {
      width: 100px !important;
      height: 100px !important;
      margin-bottom: 20px;
      color: var(--text-secondary) !important;
    }

    h4 {
      font-size: 30px;
      font-weight: normal;
      text-align: center;
    }

    p {
      font-size: 18px;
      margin-bottom: 15px;
    }

    small {
      color: #fff;
    }
  }

  &__submitted {
    display: flex;
    flex-direction: column;
    align-items: center;

    .submitted-container-border {
      .submitted-container {
        background-color: var(--text-primary);
        height: 100%;
        width: 100%;
        border-radius: inherit;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 66px;
          height: 100px;
          path {
            color: #96e189;
          }
        }
      }
    }

    h4 {
      font-size: 25px;
      font-weight: normal;
    }

    p {
      font-size: 18px;
      margin-bottom: 15px;
    }

    small {
      color: var(--text-muted-color);
      text-align: center;
      font-size: 10px;
    }

    a {
      margin-top: 20px;
      font-size: 15px;
      color: var(--text-secondary);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 20px;
    color: #fff;
    margin-bottom: 30px;
    img {
      width: 30px;
      height: 30px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    &__mint {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &__input-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        input {
          min-width: 50px;
          max-width: 60px;
          border: none;
          border-radius: 100px;
          padding: 0.5rem;
          text-align: center;

          &:focus-visible {
            outline: none;
          }
        }
      }

      .body-card__body__mint__actions {
        display: flex;
        justify-content: center;
        gap: 10px;

        button {
          background-color: #000;
          padding: 0.5rem 1rem;
        }
      }
    }

    &__cost {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      p {
        font-size: 22px;
      }

      small {
        justify-self: flex-end;
      }
    }
  }

  &__footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;

    button {
      background-color: var(--text-secondary);
      padding: 0.5rem 2rem;

      &:hover {
        background-color: #e74483;
      }
    }
  }
}

.reject {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  path {
    stroke: #f35f55;
  }
}

.reject-text {
  font-size: 15px;
  max-width: 400px;
  text-align: center;
}

@media screen and (max-width: 586px) {
  .mint-card {
    min-width: 100%;
  }
}
